// 新聞類別 tab 按鈕 （首頁、新聞列表頁）
'use client';
import React from 'react';
import { NEWS_CATEGORY_MAP } from '@/common/constant';
import useScrollDirection from '@/hook/useScrollDirection';
import IndexEventCalendarSwiper from '../eventCalendar/IndexEventCalendarSwiper'; // 日曆輪播

const LatestNewsTab = ({ location, category, setCategory, t, locale = null }) => {
    const scrollDirection = useScrollDirection();

    // 分類切換
    const onClickCategory = (c) => {
        setCategory(c);
        if (location === 'news')
            history.replaceState({}, '', `${locale === 'tw' ? '' : '/cn'}/news`),
                window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div
            className={`w-full relative ulg:bg-white z-[5] trans ${
                location === 'news'
                    ? ''
                    : `ulg:fixed left-0 ${
                          scrollDirection === 'down' ? 'ulg:top-0' : 'ulg:top-[54px]'
                      }`
            }`}
        >
            {location !== 'news' && (
                // 日曆輪播
                <IndexEventCalendarSwiper locale={locale} />
            )}
            <div
                className={`relative w-full noScrollX ulg:py-0 z-[1]
                ulg:w-screen ulg:left-[50%] ulg:translate-x-[-50%]
                ${
                    location === 'news'
                        ? ''
                        : 'border-b-1 border25 py-[4px] mx-auto max-w-[1072px] uxl:pl-[26px] ulg:px-[30px] umd:!px-0 z-[1]'
                }`}
            >
                <div
                    className={`w-max flex items-center relative h-[39px]
                ${
                    location === 'news'
                        ? 'gap-x-[22px] pb-[8px] min-w-full ulg:px-[30px] usm:!px-[15px]'
                        : 'gap-x-[9px] usm:gap-x-[8px]'
                }`}
                >
                    {Object.values(NEWS_CATEGORY_MAP).map((btn, i) => (
                        <React.Fragment key={'tab' + btn + i}>
                            {/* 切換類別按鈕 */}
                            <button
                                onClick={() => onClickCategory(btn)}
                                className={`py-[14px] text-btn1b trans text-center
                                ${
                                    category === btn ? 'text-pale' : 'text-black hover:text-black5'
                                } ${location === 'news' ? '' : 'table-bar-category px-[16px]'}`}
                            >
                                <span
                                    className={`text-center underline pl-[.5px] tracking-wider ${
                                        category === btn
                                            ? 'decoration-pale'
                                            : 'decoration-transparent'
                                    }`}
                                >
                                    {t(btn.toUpperCase())}
                                </span>
                            </button>
                            {i < Object.values(NEWS_CATEGORY_MAP)?.length - 1 &&
                                location !== 'news' && (
                                    <span className="w-[1px] h-[10px] border-l-1 border-black2 border-opacity-[.5]" />
                                )}
                        </React.Fragment>
                    ))}
                </div>
            </div>

            {/* 背景遮擋用 */}
            {location !== 'news' && (
                <div className="hidden filter-bg ulg:block bg-white w-full absolute h-[60px] top-[-30px] left-0 z-[0]" />
            )}
        </div>
    );
};

export default LatestNewsTab;
