import apiClient from './apiClient';

class EventCalendarClient {
    // 取得首頁輪播日曆
    getIndexEventCalendar = () => apiClient.get('/event-calendar/event-calendar-list');
}

const eventCalendarClient = new EventCalendarClient();

export default eventCalendarClient;
