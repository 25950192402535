// 恐懼貪婪＋幣價
'use client';

import Greedy from '@/components/index/Greedy'; // 恐懼貪婪指數
import CoinPrice from '@/components/index/CoinPrice'; // 幣價顯示
import GreedyPop from './GreedyPop'; // 指數說明彈窗

const GreedyAndPrice = ({ greed }) => {
    return (
        <div className="relative z-[6] max-w-[1196px] ulg:hidden h-[47px] flex items-center">
            <div className="relative z-[1] uxl:noScrollX">
                {/* 恐懼貪婪指數 */}
                <div className="between-center gap-x-[35px] py-[7px] px-[45px] uxl:px-[25px] w-full uxl:w-max">
                    {greed && <Greedy greed={greed} />}

                    {/* 幣價顯示 */}
                    <CoinPrice />
                </div>
            </div>

            {/* 指數說明彈窗 */}
            {greed && <GreedyPop greed={greed} />}
        </div>
    );
};

export default GreedyAndPrice;
