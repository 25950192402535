'use client';
// 首頁日曆輪播
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIndexMarginTop } from '@/slices/popSlice';
import eventCalendarClient from '../../common/apiClient/eventCalendarClient';
import IndexEventCalendarComponent from '../flash/IndexEventCalendarComponent'; // 日曆元件
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

const IndexEventCalendarSwiper = ({ locale }) => {
    const dispatch = useDispatch();
    const [calendars, setCalendars] = useState([]); // 日曆

    // 取得日曆資料
    const getEventCalendar = async () => {
        try {
            const { eventCalendarList } = await eventCalendarClient.getIndexEventCalendar();

            if (eventCalendarList && eventCalendarList?.length) {
                setCalendars(eventCalendarList);
                dispatch(setIndexMarginTop(34));
            }
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        getEventCalendar();
    }, []);

    if (calendars.length)
        return (
            <div
                className="relative z-[2] mx-auto h-[34px] mt-[8px] w-[calc(100%-60px)] max-w-[1072px]
                bg-[url('/images/calendar/calendar_bg.png')] rounded-[4px] overflow-hidden bg-cover bg-center
                ulg:w-screen ulg:mt-0 ulg:rounded-[0]"
            >
                {/* 遮罩 */}
                <div className="absolute top-0 left-0 w-full h-full z-[1] backdrop-filter-[2px] bg-[#000] bg-opacity-[.4]" />

                {/* 輪播 */}
                <Swiper
                    className="w-full h-full"
                    direction={'vertical'}
                    loop={true}
                    modules={[Autoplay]}
                    spaceBetween={0}
                    slidesPerView={1}
                    autoplay={{
                        delay: 5000,
                    }}
                >
                    {calendars?.map((calendar, i) => (
                        <SwiperSlide key={calendar?.title + i}>
                            <IndexEventCalendarComponent calendar={calendar} locale={locale} />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        );

    return <></>;
};

export default IndexEventCalendarSwiper;
