// 快訊輪播
'use client';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import Link from 'next/link';
import moment from 'moment';

import '../../styles/swipers.scss'; // 輪播樣式

const FlashSwiper = ({ flashes }) => {
    return (
        <Swiper
            id="flash-swiper"
            className="w-full overflow-hidden !mt-[2px]"
            loop={true}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            autoplay={{
                delay: 5000,
            }}
        >
            {flashes?.map((f) => (
                <SwiperSlide
                    key={f?.[0].title}
                    className="pb-[15px] [&_a]:py-[14px] [&_a]:flex [&_a]:flex-col [&_a]:items-start [&_a]:gap-y-[8px] uxxl:[&_a]:gap-y-[6px] text-btn1 text-black9"
                >
                    <Link href={`/flash/${f?.[0]?.id}`} className="group" prefetch={false}>
                        <span className="hover-text line-clamp-2 !underline-offset-1">
                            {f?.[0]?.title}
                        </span>
                        <span className="text-bd3n text-black4" suppressHydrationWarning>
                            {moment(f?.[0]?.publishedTime)?.fromNow()}
                        </span>
                    </Link>
                    <hr className="border-dotted w-full border-black4 border-opacity-[.7]" />
                    <Link href={`/flash/${f?.[1]?.id}`} className="group" prefetch={false}>
                        <span className="hover-text line-clamp-2 !underline-offset-1">
                            {f?.[1]?.title}
                        </span>
                        <span className="text-bd3n text-black4" suppressHydrationWarning>
                            {moment(f?.[1]?.publishedTime)?.fromNow()}
                        </span>
                    </Link>
                    <hr className="border-dotted w-full border-black4 border-opacity-[.7]" />
                    <Link href={`/flash/${f?.[2]?.id}`} className="group" prefetch={false}>
                        <span className="hover-text line-clamp-2 !underline-offset-1">
                            {f?.[2]?.title}
                        </span>
                        <span className="text-bd3n text-black4" suppressHydrationWarning>
                            {moment(f?.[2]?.publishedTime)?.fromNow()}
                        </span>
                    </Link>
                    <hr className="border-dotted w-full border-black4 border-opacity-[.7]" />
                    <Link href={`/flash/${f?.[3]?.id}`} className="group" prefetch={false}>
                        <span className="hover-text line-clamp-2 !underline-offset-1">
                            {f?.[3]?.title}
                        </span>
                        <span className="text-bd3n text-black4" suppressHydrationWarning>
                            {moment(f?.[3]?.publishedTime)?.fromNow()}
                        </span>
                    </Link>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default FlashSwiper;
